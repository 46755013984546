import { Suspense, lazy } from 'react';
import Loader from './components/lib/Loader';
import { fetcher } from './services/ApiServices';
import { API_GET_DATA } from './services/ApiEndPoint';
import { preload } from 'swr';

const Router = lazy(() => {
  return new Promise((resolve) => {
    preload(API_GET_DATA, fetcher);
    setTimeout(() => resolve(import('./Routes')), 600);
  });
});

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router />
    </Suspense>
  );
};

export default App;
